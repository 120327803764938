<template>
  <div>
    <br />
    <SuccessMoviment v-if="successMoviment" :rentId="rentId" :movimentId="movimentId"
      msgSuccess="Saída processada com sucesso!" />
    <div v-show="!successMoviment">
      <div class="text-center" v-show="!loading">
        <span class="check">
          <i class="fa-solid fa-clipboard-check"></i>
          Saída
        </span>
      </div>
      <br />
      <div v-show="loading">
        <Loading type="line" :center="true" />
        <div class="title">Aguarde, estamos preparando a saída...</div>
      </div>
      <Molded v-if="!loading">
        <TableTotalRecords :totalRecords="content.items.length" />
        <ScrollBar :minHeight="0" :maxHeight="600">
          <table class="table table-responsive-xs">
            <thead>
              <tr>
                <th>
                  <span class="title-header">Produto</span>
                </th>
                <th>
                  <span class="title-header">Descrição</span>
                </th>
                <th class="text-center">
                  <span class="title-header">Locação</span>
                </th>
                <th class="text-center">
                  <span class="title-header">Saída</span>
                </th>
                <th class="text-center">
                  <span class="title-header">Estoque</span>
                </th>
                <th class="text-center">
                  <span class="title-header">Status</span>
                </th>
              </tr>
            </thead>
            <tbody v-for="item in content.items">
              <tr>
                <td class="td-product">{{ item.productFullName }}</td>
                <td class="">
                  <div class="td-description">
                    <InputTextEdit v-model="item.description" />
                  </div>
                </td>
                <td class="text-center td-quantity">{{ item.totalRent }}</td>
                <td class="text-center td-quantity">
                  {{ item.totalSelected }}
                </td>
                <td class="text-center">
                  <i v-if="item.typeProduct == 3" class="fas fa-people-carry"></i>
                  <ItemRentStockAvailability v-if="item.typeProduct != 3" :data="item.available" :item="item"
                    :showButtonBack="false" />
                </td>
                <td class="text-center">
                  <div v-if="!hasProductInStock(item)">
                    <span class="status-danger">
                      <i class="fa-solid fa-brake-warning"></i>
                    </span>
                  </div>
                  <div v-else>
                    <span class="status-primary" v-if="item.totalSelected > item.totalRent">
                      <i class="fa-solid fa-rectangle-history-circle-plus"></i>
                    </span>
                    <span class="status-success" v-if="item.totalSelected == item.totalRent">
                      <i class="fa-solid fa-circle-check"></i>
                    </span>
                    <span class="status-warning" v-if="item.totalSelected < item.totalRent &&
                      item.totalSelected != 0
                      ">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </span>
                    <span class="status-danger" v-if="item.totalSelected == 0">
                      <i class="fa-solid fa-do-not-enter"></i>
                    </span>
                  </div>
                </td>
              </tr>
              <td class="div-patrimony" colspan="8" v-if="item.patrimony.length > 0">
                <div v-for="patrimony in item.patrimony">
                  <span>
                    <i class="fa-solid fa-circle-arrow-right"></i>
                    {{ patrimony.barCode }}
                  </span>
                </div>
              </td>
            </tbody>
            <tbody v-show="content.items.length == 0">
              <tr>
                <td colspan="12">
                  <span>Nenhum produto encontrado!</span>
                </td>
              </tr>
            </tbody>
          </table>
        </ScrollBar>
      </Molded>
      <br />
      <Alert type="danger" v-if="isStockNegative">
        <span>
          Não é permitido saída de estoque negativo. Verifique e ajuste os produtos em falta.
        </span>
      </Alert>
      <br v-if="isStockNegative" />
      <Modal title="Painel" :width="1000" v-if="showModal('timeLineAvailability')">
        <TimeLineAvailability />
      </Modal>
      <div class="text-center">
        <span v-show="!loading">
          <slot></slot>
        </span>
        <Button v-if="!loading" _key="btnGenerateOutput" type="success" :disabled="isStockNegative" title="Gerar Saída"
          classIcon="fa-solid fa-circle-check" size="large" :clicked="generate" />
      </div>
    </div>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";

import SuccessMoviment from "../SuccessMoviment.vue";
import TimeLineAvailability from "../../shared/TimeLineAvailability.vue";
import ItemRentStockAvailability from "../../../rental/items-rent/ItemRentStockAvailability.vue";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CheckOutput",
  components: {
    Molded,
    Button,
    ScrollBar,
    Loading,
    Modal,
    Alert,
    InputTextEdit,
    ItemRentStockAvailability,
    TimeLineAvailability,
    TableTotalRecords,
    SuccessMoviment,
  },
  props: {
    rentId: String,
  },
  data() {
    return {
      urlCheck: "/api/v1/moviment/generate-output/check-output",
      urlGenerate: "/api/v1/moviment/generate-output/generate",
      content: [],
      loading: true,
      successMoviment: false,
      movimentId: "",
    };
  },
  mounted() {
    this.checkOutput();
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
    ...mapGetters("generateMoviment", ["productSelectedByPeriod"]),
    ...mapState("user", ["userLogged"]),
    ...mapState("generateMoviment", ["periodRent", "productRent"]),
    isStockNegative() {
      if (
        this.content.isStockNegative &&
        !this.userLogged.parameterStock.allowNegative
      )
        return true;
      return false;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("generateMoviment", ["cleanProductSelected"]),
    hasProductInStock(item) {
      if (item.available.totalStock >= item.totalSelected) return true;
      if (item.available.totalStock < item.totalSelected) return false;
    },
    checkOutput() {
      let obj = {
        periodRent: this.periodRent.fullPeriod,
        productRent: this.productRent,
        productSelected: this.productSelectedByPeriod,
      };
      let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };

      this.postApi(params).then((response) => {
        if (response.success) {
          this.content = response.content;
        }
        this.loading = false;
      });
    },
    generate() {
      this.loading = true;
      let obj = {
        periodRentId: this.periodRent.id,
        rentId: this.rentId,
        items: this.content.items,
      };
      let params = { url: this.urlGenerate, obj: obj, notNotifyToast: false };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.movimentId = response.content;
          let self = this;
          setTimeout(function () {
            self.successMoviment = true;
            self.loading = false;
            self.cleanProductSelected();
            self.removeLoading(["btnGenerateOutput"]);
          }, 300);

        } else {
          this.removeLoading(["btnGenerateOutput"]);
          this.loading = false;
        }
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "timeLineAvailability") {
          this.openModal("timeLineAvailability");
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-description {
  max-width: 300px !important;
  word-wrap: break-word !important;
  font-size: 14px;
  color: #757d8c;
  font-style: italic;
}

.td-quantity {
  font-size: 16px;
}

.div-title {
  margin-bottom: 10px;
}

.title {
  font-size: 15px;
}

.check {
  font-size: 30px;
}

.div-patrimony {
  background-color: #fafafc;
  margin-right: 14%;
}

.status-primary {
  color: blue !important;
  font-size: 20px;
}

.status-success {
  color: rgb(29, 158, 29);
  font-size: 20px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 20px;
}

.status-danger {
  color: red;
  font-size: 20px;
}
</style>