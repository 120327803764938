<template>
  <div>
    <Panel module="Movimentação" title="Gerar Saída" layout="large" :showFilter="false" :showSearch="false"
      :showButtons="false">
      <div slot="content-main">
        <GenerateOutput :rentId="rentId" />
      </div>
    </Panel>
  </div>
</template>
<script>
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import GenerateOutput from "../../../components/modules/stock/moviment/output/GenerateOutput.vue";

import { mapMutations } from "vuex";

export default {
  name: "GenerateOutputView",
  components: {
    Panel,
    GenerateOutput,
  },
  data() {
    return {
      rentId: this.$route.params.id,
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>